import React from 'react';
import isLoggedIn from 'src/utils/isLoggedIn';
import inBrowser from 'src/utils/inBrowser';
import Reset from 'src/components/auth/Reset';

const ResetPasswordPage = () => {
    return (
        <div>
            <Reset />
        </div>
    );
};

export default ResetPasswordPage;
